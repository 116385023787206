// @flow

/**
 * Module dependencies.
 */

import { type Payspace as PayspaceData } from 'types/payspace';
import { isNil, template } from 'lodash';
import { resolve } from 'url';
import { useEffect, useState } from 'react';
import axios from 'axios';

/**
 * `Payspace` type.
 */

type Payspace = {|
  data: ?PayspaceData,
  error: ?Object,
  loading: boolean
|};

/**
 * Export `SlykApiConfig` type.
 */

export type SlykApiConfig = {|
  baseUrl: string,
  endpoints: ?Object
|};

/**
 * Export `usePayspace` hook.
 */

export function usePayspace(
  slug: ?string,
  slykApiConfig: ?SlykApiConfig
): Payspace {
  const [isLoading, setIsLoading] = useState(false);
  const [payspaceData, setPayspaceData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const apiBaseUrl = slykApiConfig?.baseUrl;
    const payspaceEndpointValue = slykApiConfig?.endpoints?.payspace;

    if (isNil(slug) || !apiBaseUrl || !payspaceEndpointValue) {
      return;
    }

    const payspaceEndpoint = template(payspaceEndpointValue);

    setIsLoading(true);

    axios
      .get(resolve(apiBaseUrl, payspaceEndpoint({ slug })))
      .then(({ data }) => {
        setPayspaceData(data?.data);
        setIsLoading(false);
      })
      .catch(error => {
        setError(error);
        setIsLoading(false);
      });
  }, [slug, slykApiConfig]);

  return {
    data: payspaceData,
    error,
    loading: isLoading
  };
}
