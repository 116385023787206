// @flow

/**
 * Module dependencies.
 */

import { Container, Grid } from 'components/core/layout';
import { type DetailsProps } from './details';
import { type SlykApiConfig, usePayspace } from 'hooks/use-payspace';
import { Type } from '@seegno-labs/react-components/typography';
import { color, media, units } from '@seegno-labs/react-components/styles';
import { ifProp } from 'styled-tools';
import { useIntersection } from 'hooks/use-intersection';
import Button from 'components/core/button';
import GatsbyBackgroundImage from 'components/core/images/background-image';
import GatsbyImage from 'components/core/images/image';
import MobileAppPreview, { videoHeight } from 'components/mobile-app-preview';
import RawHtml from '@seegno-labs/react-components/raw-html';
import React, { type Node } from 'react';
import pillMask from 'assets/images/pill-mask.webp';
import styled, { css } from 'styled-components';
import useBreakpoint from 'hooks/use-breakpoint';

/**
 * `Props` type.
 */

type Props = {|
  ...DetailsProps,
  backgroundFluidImage?: Object,
  children?: Node,
  fluidImage?: Object,
  isLargeImage?: boolean,
  slug?: ?string,
  slykApiConfig?: ?SlykApiConfig
|};

/**
 * `Section` styled component.
 */

const Section = styled.header`
  position: relative;

  ${media.min('md')`
    align-items: center;
    display: flex;
    justify-content: center;
  `}

  ${media.min('lg')`
    min-height: 100vh;
  `}
`;

/**
 * `StyledGrid` styled component.
 */

const StyledGrid = styled(Grid)`
  grid-template-columns: repeat(12, 1fr);
  padding: ${units(10)} 0;

  @media (min-width: 360px) {
    margin-bottom: ${units(3)};
  }

  ${media.min('ms')`
    grid-column-gap: ${units(4)};
    margin-bottom: 0;
    padding: ${units(14)} 0;
  `}

  ${media.min('md')`
    padding: ${units(18)} 0;
  `}
`;

/**
 * `HeaderBackgroundImage` styled component.
 */

const HeaderBackgroundImage = styled(GatsbyBackgroundImage)`
  height: 80%;
  opacity: 0.85 !important;
  right: -120px !important;
  top: ${units(-23)} !important;

  &,
  &::after,
  &::before {
    background-position: bottom center !important;
  }

  ${media.min('xs')`
    bottom: ${units(-12.5)} !important;
    height: 100%;
    top: 0 !important;
  `}

  ${media.min('xl')`
    bottom: -130px !important;
  `}

  ${media.min('xxl')`
    bottom: -230px !important;
  `}
`;

/**
 * `MobileAppPreviewWrapper` styled component.
 */

const MobileAppPreviewWrapper = styled.div`
  grid-column: 1;
  grid-row: 1;
  height: 100%;
  max-width: ${units(40)};
  position: relative;
  transition: transform 1s ease-in-out;

  ${ifProp('isIntersecting', css`
    transform: scale(1);
  `, css`
    transform: scale(0.95);
  `)}

  ${media.min('xs')`
    transform: none;
  `}

  ${media.min('ms')`
    margin: 0;
    max-width: 285px;
  `}

  ${media.min('md')`
    margin: 0 ${units(8.75)};
  `}
`;

/**
 * `MediaWrapper` styled component.
 */

const MediaWrapper = styled.div`
  display: grid;
  grid-column: 1 / -1;
  grid-row: 2;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  justify-items: center;
  position: relative;

  ${media.min('ms')`
    grid-column: 7 / -1;
    grid-row: 1;
    grid-template-rows: ${videoHeight}px;
  `}

  ${media.min('md')`
    justify-items: initial;
  `}
`;

/**
 * `PillWrapper` styled component.
 */

const PillWrapper = styled.div`
  grid-column: 1;
  grid-row: 1;
  height: 432px;
  max-width: 682px;
  width: 100%;

  ${media.max('ms')`
    display: none;
  `}
`;

/**
 * `PillImage` styled component.
 */

const PillImage = styled(GatsbyImage)`
  height: 100%;
  mask: url(${pillMask});
  mask-position: bottom;
  mask-repeat: no-repeat;
  mask-size: contain;
`;

/**
 * `TextWrapper` styled component.
 */

const TextWrapper = styled.div`
  align-self: center;
  grid-column: 1 / -1;
  grid-row: 1;
  padding-top: ${units(1)};
  position: relative;

  @media (min-width: 360px) {
    padding-top: ${units(5)};
  }

  ${media.min('ms')`
    align-self: initial;
    grid-column: 1 / span 6;
    padding-top: ${units(9)};
  `}

  ${media.min('md')`
    grid-column: 2 / span 5;
    padding-top: 44px;
  `}

  ${media.min('xl')`
    ${ifProp('isLargeImage', css`
      padding-top: ${units(20)};
    `)}
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H2)`
  color: ${color('secondary')};
  margin-bottom: ${units(3)};

  @media (min-width: 414px) {
    font-size: 56px;
    font-weight: 300;
    letter-spacing: -2px;
    line-height: 56px;
  }

  ${media.min('lg')`
    font-size: 79px;
    letter-spacing: -3px;
    line-height: 80px;
    margin-bottom: ${units(5)};
  `}

  strong, b {
    color: ${color('primary')};
    font-weight: 600;
  }
`;

/**
 * `Description` styled component.
 */

const Description = styled(Type.H5).attrs({ as: 'div' })`
  color: ${color('secondary')};
  margin-bottom: ${units(3)};

  ${media.min('ms')`
    padding-right: 18.33%;
  `}
`;

/**
 * `ButtonWrapper` styled component.
 */

const ButtonWrapper = styled.div`
  margin-bottom: ${units(3)};

  @media (min-width: 360px) {
    margin-bottom: ${units(6.5)};
  }

  ${media.min('md')`
    margin-bottom: 0;
  `}
`;

/**
 * `GetSlykButton` styled component.
 */

const GetSlykButton = styled(Button)`
  font-weight: 400;
  min-height: 54px;
  width: 100%;

  ${media.min('ms')`
    min-height: 42px;
    padding: ${units(1)} ${units(4)};
    width: initial;
  `}
`;

/**
 * `Header` component.
 */

function Header(props: Props): Node {
  const {
    backgroundFluidImage,
    buttonLabel,
    buttonUrl,
    children,
    description,
    fluidImage,
    isLargeImage = false,
    slug,
    slykApiConfig,
    title
  } = props;

  const { data: payspace, loading } = usePayspace(slug, slykApiConfig);
  const { isIntersecting, ref } = useIntersection();
  const isMobile = useBreakpoint('ms', 'max');

  return (
    <Section>
      <HeaderBackgroundImage fluid={backgroundFluidImage} />

      <Container>
        <StyledGrid>
          <MediaWrapper>
            {children ?? (
              <>
                <PillWrapper>
                  <PillImage fluid={fluidImage} />
                </PillWrapper>

                <MobileAppPreviewWrapper
                  isIntersecting={isIntersecting}
                  ref={ref}
                >
                  <MobileAppPreview
                    isLoading={loading}
                    payspace={payspace}
                  />
                </MobileAppPreviewWrapper>
              </>
            )}
          </MediaWrapper>

          <TextWrapper isLargeImage={isLargeImage}>
            {title && (
              <Title>
                <RawHtml>
                  {title}
                </RawHtml>
              </Title>
            )}

            {description && (
              <Description isLargeImage={isLargeImage}>
                <RawHtml>
                  {description}
                </RawHtml>
              </Description>
            )}

            {buttonLabel && buttonUrl && (
              <ButtonWrapper>
                <GetSlykButton
                  size={isMobile ? 'large' : 'medium'}
                  to={buttonUrl}
                >
                  {buttonLabel}
                </GetSlykButton>
              </ButtonWrapper>
            )}
          </TextWrapper>
        </StyledGrid>
      </Container>
    </Section>
  );
}

/**
 * Export `Header` component.
 */

export default Header;
