// @flow

/**
 * Module dependencies.
 */

import { Icon } from '@seegno-labs/react-components/media';
import { type Payspace } from 'types/payspace';
import { Type } from '@seegno-labs/react-components/typography';
import { color, media, units } from '@seegno-labs/react-components/styles';
import { compact, filter, find, isEmpty, map, noop } from 'lodash';
import { ifProp, theme } from 'styled-tools';
import PayspaceLogo, { payspaceLogoStyle } from './payspace-logo';
import React, {
  type Node,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';

import soundOffIcon from 'assets/svg/32/sound-off.svg';
import soundOnIcon from 'assets/svg/32/sound-on.svg';
import styled from 'styled-components';
import useTranslate from 'hooks/use-translate';

/**
 * `VideosList` type.
 */

type VideosList = Array<HTMLVideoElement>;

/**
 * `VideosContextOptions` type.
 */

type VideosContextOptions = {|
  isMuted: boolean,
  setIsMuted: (value: boolean) => void,
  sortedVideos: Array<string>,
  videosList: VideosList
|};

/**
 * Export `VideosContext`.
 */

export const VideosContext = createContext<VideosContextOptions>({
  isMuted: true,
  setIsMuted: noop,
  sortedVideos: [],
  videosList: []
});

/**
 * `Props` type.
 */

type Props = {|
  payspace: ?Payspace
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  border-radius: inherit;
  height: 100%;
  position: relative;
`;

/**
 * `Video` styled component.
 */

const Video = styled.video`
  border-radius: inherit;
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

/**
 * `SoundControlButton` styled component.
 */

const SoundControlButton = styled.button`
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  bottom: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: ${ifProp('isVisible', 1, 0)};
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity ${theme('animations.defaultTransition')};
  width: 100%;
`;

/**
 * `SoundMask` styled component.
 */

const SoundMask = styled.div`
  background-color: ${color.transparentize('black', 0.1)};
  border-radius: ${units(8.5)};
  height: ${units(12.5)};
  position: absolute;
  width: ${units(12.5)};

  ${media.min('xs')`
    height: ${units(18)};
    width: ${units(18)};
  `}
`;

/**
 * `SoundIconWrapper` styled component.
 */

const SoundIconWrapper = styled.div`
  align-items: center;
  background-color: ${color.transparentize('black', 0.5)};
  border-radius: ${units(8.5)};
  display: flex;
  height: ${units(7)};
  justify-content: center;
  width: ${units(7)};

  ${media.min('xs')`
    height: ${units(8.5)};
    width: ${units(8.5)};
  `}
`;

/**
 * `SoundIcon` styled component.
 */

const SoundIcon = styled(Icon)`
  position: absolute;
`;

/**
 * `Gradient` styled component.
 */

const Gradient = styled.div`
  background: linear-gradient(180deg, ${color.transparentize('black', 0)} 0%, ${color.transparentize('black', 0.3)} 63.97%);
  border-radius: inherit;
  bottom: 0;
  height: 40%;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
`;

/**
 * `InformationWrapper` styled component.
 */

const InformationWrapper = styled.div`
  bottom: 5.5%;
  left: 7.5%;
  pointer-events: none;
  position: absolute;
  right: 7.5%;
`;

/**
 * `LogoNameWrapper` styled component.
 */

const LogoNameWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-column-gap: ${units(1)};
  grid-template-columns: max-content 1fr;
  margin-bottom: ${units(0.5)};
`;

/**
 * `PayspaceLogoWrapper` styled component.
 */

const PayspaceLogoWrapper = styled.div`
  ${payspaceLogoStyle}

  height: 25px;
  position: relative;
  width: 25px;
`;

/**
 * Font family.
 */

const fontFamily = 'Saira-Regular, Saira';

/**
 * `PayspaceName` styled component.
 */

const PayspaceName = styled(Type.Small).attrs({ as: 'p' })`
  color: ${color('white')};
  font-family: ${fontFamily};
  font-size: 12px;
  letter-spacing: 0.25px;
  line-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

/**
 * `PayspaceTitle` styled component.
 */

const PayspaceTitle = styled(Type.Small).attrs({ as: 'p' })`
  color: ${color('white')};
  font-family: ${fontFamily};
  font-size: 13px;
  letter-spacing: 0.25px;
  line-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

/**
 * Get video.
 */

function getVideo(
  videosList: VideosList,
  currentVideo: HTMLVideoElement,
  isPreviousVideo: boolean
): Array<?HTMLVideoElement> {
  return compact(map(videosList, (video, index) => {
    if (video?.id === currentVideo?.id) {
      if (isPreviousVideo) {
        return videosList[index - 1];
      }

      return videosList[index + 1];
    }

    return;
  }));
}

/**
 * Play current video.
 */

function playCurrentVideo(videosList: VideosList, currentVideo: HTMLVideoElement) {
  if (isEmpty(videosList)) {
    return;
  }

  const visibleVideos = filter(videosList, video => {
    if (video) {
      const videoRect = video.getBoundingClientRect();

      if (videoRect.width === 0 && videoRect.height === 0) {
        return;
      }

      return video;
    }
  });

  const [previousVideo] = getVideo(visibleVideos, currentVideo, true);
  const [nextVideo] = getVideo(visibleVideos, currentVideo, false);

  if (previousVideo || nextVideo) {
    if (previousVideo && !previousVideo.paused) {
      previousVideo.pause();
    }

    if (nextVideo && !nextVideo.paused) {
      nextVideo.pause();
    }

    return currentVideo.play();
  }

  return currentVideo.play();
}

/**
 * `VideoPreview` component.
 */

function VideoPreview({ payspace }: Props): Node {
  const [isSoundControlVisible, setSoundControlVisible] = useState(false);
  const translate = useTranslate();
  const videoRef = useRef();
  const payspaceName = payspace?.name;
  const payspaceLogoUrl = payspace?.logo?.url;
  const {
    isMuted,
    setIsMuted,
    sortedVideos,
    videosList
  } = useContext(VideosContext);

  useEffect(() => {
    const video = videoRef.current;

    if (!video) {
      return;
    }

    videosList.push(video);

    const observer = new IntersectionObserver(([entry]) => {
      const orderedVideoList = map(sortedVideos, slug => {
        return find(videosList, video => video?.id === slug);
      });

      if (entry.isIntersecting && entry.intersectionRatio > 0.5) {
        playCurrentVideo(orderedVideoList, video);
      } else {
        video.pause();
      }
    }, { threshold: [0.25, 0.5, 0.75] });

    observer.observe(video);

    return () => observer.disconnect();
  }, [sortedVideos, videosList]);

  return (
    <>
      <Wrapper
        onMouseLeave={() => setSoundControlVisible(false)}
        onMouseOver={() => setSoundControlVisible(true)}
      >
        <Video
          id={payspace?.slug}
          loop
          muted={isMuted}
          playsInline
          ref={videoRef}
        >
          <source src={payspace?.background?.url} />
        </Video>

        <SoundControlButton
          aria-label={translate(`labels.${isMuted ? 'soundOff' : 'soundOn'}`)}
          isVisible={isSoundControlVisible}
          onClick={() => setIsMuted(!isMuted)}
        >
          <SoundMask />

          <SoundIconWrapper>
            <SoundIcon
              aria-hidden
              color={'white'}
              icon={isMuted ? soundOffIcon : soundOnIcon}
              size={units(4)}
            />
          </SoundIconWrapper>
        </SoundControlButton>
      </Wrapper>

      <Gradient />

      <InformationWrapper>
        <LogoNameWrapper>
          <PayspaceLogoWrapper hasAcronym={!payspaceLogoUrl}>
            <PayspaceLogo
              payspaceLogo={payspaceLogoUrl}
              payspaceName={payspaceName}
            />
          </PayspaceLogoWrapper>

          <PayspaceName>
            {payspaceName}
          </PayspaceName>
        </LogoNameWrapper>

        <PayspaceTitle>
          {payspace?.title}
        </PayspaceTitle>
      </InformationWrapper>
    </>
  );
}

/**
 * Export `VideoPreview` component.
 */

export default VideoPreview;
