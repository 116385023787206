// @flow

/**
 * Get first character.
 */

function getFirstCharacter(value) {
  const codePoint = value.codePointAt(0);

  if (codePoint === undefined) {
    return '';
  }

  return String.fromCodePoint(codePoint);
}

/**
 * Export `getAcronym`.
 */

export function getAcronym(name: ?string): ?string {
  if (!name) {
    return;
  }

  return name
    .toUpperCase()
    .split(' ')
    .map(getFirstCharacter)
    .join('')
    .substring(0, 2);
}
