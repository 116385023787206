// @flow

/**
 * Module dependencies.
 */

import { Fill } from 'components/core/layout';
import { units } from '@seegno-labs/react-components/styles';
import PayspaceLogo, { payspaceLogoStyle } from './payspace-logo';
import React, { type Node } from 'react';
import noImage from 'assets/images/no-image@2x.webp';
import styled from 'styled-components';

/**
 * `NoImage` styled component.
 */

const NoImage = styled.img`
  border-radius: inherit;
  object-fit: cover;
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled(Fill)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

/**
 * `PayspaceLogoWrapper` styled component.
 */

const PayspaceLogoWrapper = styled.div`
  ${payspaceLogoStyle}

  height: ${units(8)};
  margin: ${units(2)} 0;
  position: relative;
  width: ${units(8)};
`;

/**
 * `NoImagePreview` component.
 */

function NoImagePreview(): Node {
  return (
    <>
      <NoImage
        alt={''}
        height={'100%'}
        src={noImage}
        width={'100%'}
      />

      <ContentWrapper>
        <PayspaceLogoWrapper>
          <PayspaceLogo />
        </PayspaceLogoWrapper>
      </ContentWrapper>
    </>
  );
}

/**
 * Export `NoImagePreview` component.
 */

export default NoImagePreview;
