// @flow

/**
 * Module dependencies.
 */

import { BackgroundImage } from '@seegno-labs/react-components/media';
import { color } from '@seegno-labs/react-components/styles';
import { css } from 'styled-components';
import { getAcronym } from 'utils/acronym';
import { ifProp } from 'styled-tools';
import React, { type Node } from 'react';
import logoPlaceholderImage from 'assets/images/logo-placeholder.webp';

/**
 * `Props` type.
 */

type Props = {|
  payspaceLogo?: ?string,
  payspaceName?: ?string
|};

/**
 * Export `payspaceLogoStyle`.
 */

export const payspaceLogoStyle = css`
  background-color: ${color('secondary')};
  clip-path: url(#slyk-mask);
  color: ${color('white')};
  overflow: hidden;

  ${ifProp('hasAcronym', css`
    align-items: center;
    display: flex;
    justify-content: center;
  `)}
`;

/**
 * `PayspaceLogo` component.
 */

function PayspaceLogo(props: Props): Node | string {
  const { payspaceLogo, payspaceName } = props;

  if (payspaceLogo) {
    return <BackgroundImage defaultUrl={payspaceLogo} />;
  }

  if (payspaceName) {
    return getAcronym(payspaceName);
  }

  return <BackgroundImage defaultUrl={logoPlaceholderImage} />;
}

/**
 * Export `PayspaceLogo` component.
 */

export default PayspaceLogo;
