// @flow

/**
 * Module dependencies.
 */

import { type Payspace } from 'types/payspace';
import { units } from '@seegno-labs/react-components/styles';
import NoImagePreview from './no-image-preview';
import React, { type Node } from 'react';
import VideoPreview from './video-preview';
import iphoneImage from 'assets/images/iphone.webp';
import styled from 'styled-components';

/**
 * Export `mobileVideoHeight`.
 */

export const mobileVideoHeight = 668;

/**
 * Export `videoHeight`.
 */

export const videoHeight = 590;

/**
 * `Props` type.
 */

type Props = {|
  isLoading: boolean,
  payspace: ?Payspace
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  border-radius: 40px;
  bottom: ${units(1.6)};
  left: ${units(1.25)};
  position: absolute;
  right: ${units(1.25)};
  top: ${units(1.6)};
`;

/**
 * `IphoneImage` styled component.
 */

const IphoneImage = styled.img`
  object-fit: cover;
  pointer-events: none;
  position: relative;
`;

/**
 * `MobileAppPreview` component.
 */

function MobileAppPreview(props: Props): Node {
  const { isLoading, payspace } = props;
  const { background } = payspace ?? {};
  const hasVideo = background?.type === 'video';

  return (
    <>
      <Wrapper>
        {isLoading || !hasVideo ? <NoImagePreview /> : (
          <VideoPreview payspace={payspace} />
        )}
      </Wrapper>

      <IphoneImage
        alt={'IPhone'}
        height={'100%'}
        src={iphoneImage}
        width={'100%'}
      />
    </>
  );
}

/**
 * Export `MobileAppPreview` component.
 */

export default MobileAppPreview;
